﻿.extra-details-links-container
{
	text-align:center;	
	p
	{
		margin-bottom:0.1em;
		a
		{
			font-weight:bold;
		}
	}

}

.product-hit-div 
{
	margin-bottom: 3em;
	border-bottom: 1px solid #DCDCDC;
	min-height:400px;
}

.product-tile-attributes-table
{
	border: 1px outset grey;
	padding: 1px;
	td 
	{
		border: thin inset grey;
		margin: 1px;
	}

	.part-header
	{
		text-align:center;
	}
}
.alsoBoughtProductImage
{
	width:100%;
}