﻿body {
    font-family: 'Libre Baskerville', serif;
}

h1 {
    font-family: 'Bree Serif', serif;
    background-color: #152a3d;
    color: #fff;
    padding: 0.5em;
    border-radius: 0.25em;
}
