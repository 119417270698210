﻿@import "darienColors.scss";

h3 {
    margin: 8px;
}

.btn-primary {
    background-color: $brand-primary;
}

.panel-default {
    > .panel-heading {
        background-color: #15648b;
        color: #fff;
    }
}

.verificationWarning {
    margin: 1em;

    p {
        padding: 0.5em 1em;
        line-height: 150%;
    }
}
