
.shoppingbasket-cartviewer
{
	>tbody>tr>td
	{
		vertical-align: middle;
	}
	>tfoot>tr>td:first-child
	{
		padding-top: 0;
	}
	.shoppingbasket-price
	{
		font-size:1.2em;
		font-weight: bold;
	}
	.shoppingbasket-lineitem-prices
	{
		text-align: right;
	}
	.shoppingbasket-qty
	{
		min-width:5em;
	}

}

.shoppingbasket-totals 
{
	
	width:auto;
	float:right;
	tbody td
	{
		text-align: right;
	}
	tbody tr:first-child td
	{
		border:none;
	}		
}

.checkout
{
	.shoppingbasket-totals
	{
		width:100%;
		float:none;
	}
}
.order-total
{
	margin:0;
}