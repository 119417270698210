﻿@import "darienColors.scss";

.darienCart {
    h1 {
        font-family: 'Libre Baskerville', serif;
        background-color: $brand-primary;
        font-size: 24px;
        padding: 18px 23px;
        border-radius: 3px;
        margin: 0 0 10px 0;
        border: 1px solid #ddd;
    }
}