.progress-btn
{
	position: relative;
	display: inline-block;
	padding: 0 .2em;
	outline: none;
	border: none;
	background-color: #1d9650;
	color: #fff;
	text-transform: uppercase;
	letter-spacing: 1px;
	font-size: 1em;
	line-height: 3;
	&+.progress-btn
	{
		margin-bottom:0;
		margin-left:5px;
	}
	&:hover
	{
		color: #fff;
	}

	&[disabled],
	&[disabled].loading
	{
		cursor: default;
	}

	&.error
	{
		background-color: #96281d;
		color:#fff;
		.content
		{
			&:before,
			&:after
			{
				color: #71110e;
			}
		}

		.progress
		{
			background: #852c14;

			.progress-inner
			{
				background: #711d0e;
			}
		}
	}
	&.muted
	{
		background-color:#b5d6c3;
		color: #709a71;

		.content
		{
			&:before,
			&:after
			{
				color: #709a71;
			}
		}
		
	}
	.content
	{
		position: relative;
		display: block;

		&:before,
		&:after
		{
			position: absolute;
			right: 10px;
			color: #0e7138;
			font-family: "FontAwesome";
			opacity: 0;
		}

		&:before
		{
			content: "\f00c"; //Checkmark
		}

		&:after
		{
			content: "\f00d"; //Cross
		}
	}

	&.success .content:before,
	&.error .content:after
	{
		opacity: 1;
	}


	.progress
	{
		background: #148544;

		.progress-inner
		{
			position: absolute;
			left: 0;
			background: #0e7138;
		}
	}

	&[data-horizontal] .progress-inner
	{
		top: 0;
		width: 0;
		height: 100%;
		transition: width 0.3s, opacity 0.3s;
	}

	&[data-vertical] .progress-inner
	{
		bottom: 0;
		width: 0;
		height: 0;
		transition: height 0.3s, opacity 0.3s;
	}

	&[data-style="slide-down"]
	{
		padding: 0;
		overflow: visible;
		backface-visibility: hidden;
		.content
		{
			z-index: 10;
			padding: 0 30px;
			background: #1d9650;
		}
		&.error
		{
			.content
			{
				background: #96281d;
			}
		}
		&.muted
		{
			background:#b5d6c3;
			.content
			{
				background: #b5d6c3;
			}
			
		}


		.progress
		{
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			-webkit-transition: -webkit-transform 0.2s;
			transition: transform 0.2s;
		}

		@at-root 
		{
			.progress-btn[data-style="slide-down"].loading .progress
			{
				-webkit-transform: translateY(10px);
				transform: translateY(10px);
			}
		}
	}
}
